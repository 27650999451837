<template>
    <div>
        <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #F4F6F9;">
            <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
            <Toast />
            <ConfirmPopup></ConfirmPopup>
            <div id="card1" class="card d-flex justify-content-center">

                <div class="py-4 px-4 mx-0 lg:mx-8" id="contact">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                            <div
                                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                                <img src="images/eTicket_logo_new.png" class="w-12" alt="mockup mobile" />
                            </div>
                        </div>
                        <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                            <div
                                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3">
                                <h4 style="color: #6b719b; font-size: 30px; font-weight: 700">{{ $t('register') }}</h4>


                            </div>
                            <div
                                class="flex flex-wrap align-items-center justify-content-start md:justify-content-start md:mb-0 mb-3">
                                <div class="col-12">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-12" style="margin-top: 10%;">
                                            <label for="name">{{ $t('name') }} <b style="color: red">*</b></label>
                                            <InputText id="name" type="text"   :placeholder="$t('name')" v-model="name"
                                                @keypress="validateNameInput" @copy="disableAction"
                                                @paste="disableAction" @drop="disableAction" />




                                        </div>
                                        <div class="field col-12 md:col-12" style="margin-top: 3%;">
                                            <label for="email">{{ $t('email') }}<b style="color: red">*</b></label>
                                            <InputText id="email"   :placeholder="$t('email')" type="text" v-model="email_id"
                                             @copy="disableAction"
                                                @paste="disableAction" @drop="disableAction" />


                                        </div>
                                        <div class="field col-12 md:col-12" style="margin-top: 3%;">

                                            <!-- <InputText  type="number" v-model="phone"   maxlength="10"
                                            />
                                            <label >Mobile No123.</label> -->
                                            <label for="name">{{ $t('mob_no') }}<b style="color: red">*</b></label>
                                            <InputText type="text" @keypress="validatePhoneInput"  :placeholder="$t('mob_no')" @copy="disableAction"
                                                @paste="disableAction" @drop="disableAction" maxlength="10"
                                                v-model="phone"></InputText>


                                        </div>
                                        <div class="field col-12 md:col-12" style="margin-top: 3%;">




                                            <label for="password1">{{ $t('password') }}<b
                                                    style="color: red">*</b></label>
                                            <Password id="password1" v-model="password" :feedback="false"
                                                :placeholder="$t('password')" :toggleMask="true"></Password>

                                        </div>
                                        <div class="field col-12 md:col-12" style="margin-top: 3%;">




                                            <label for="con_password">{{ $t('conf_pass') }}<b
                                                    style="color: red">*</b></label>
                                            <Password id="con_password" v-model="conf_password" :feedback="false"
                                                :placeholder="$t('conf_pass')" :toggleMask="true"></Password>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div
                                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3">
                                <div class="col-12">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-12">
                                            <Button class="p-button-help btn_light_blue" @click="submit_ticket()"
                                                :label="$t('register')" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>






            </div>
            <ScrollTop />
        </div>
    </div>
</template>

<script>
import apis from "@/apis";
import axios from "axios";
export default {
    name: 'EticketRegister',

    data() {
        return {
            isLoadingModel: false,
            fullPage: false,
            name: "",
            email_id: "",
            password: "",
            conf_password: "",
            mobileError: "",
            phone: "",
            client_id: "",
        };
    },

    mounted() {
        this.client_id = localStorage.getItem('client_id');
        this.client_id = '65a4f82f6177e69880ece5d6';
    },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
  },

    methods: {
        disableAction(event) {
            event.preventDefault();
        },
        validateNameInput(event) {
            const charCode = event.charCode || event.keyCode;
            if (
                (charCode >= 65 && charCode <= 90) ||  // Uppercase letters
                (charCode >= 97 && charCode <= 122) || // Lowercase letters
                charCode === 32 // Space
            ) {
                return true;
            }
            event.preventDefault(); // Prevent non-alphabet characters
        },
        validatePhoneInput(event) {
            const key = event.keyCode || event.which; // Use keyCode or which
            if (key < 48 || key > 57) {
                event.preventDefault(); // Prevent non-numeric characters
            }
        },
        async reset_form() {

            this.name = "",
                this.email_id = "";
            this.password = "";
            this.conf_password = "";
            this.phone = "";
            this.conf_password = "";

        },
        async submit_ticket() {

            if (this.name == '') {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    // detail: "Please Enter Username",
                    detail:  this.$t("pl_username"),
                    life: 3000,
                });
                return false;
            }
            if (this.email_id == '') {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    //detail: "Please Enter Email ID",
                    detail:this.$t("email_empty_error"),
                    life: 3000,
                });
                return false;
            }


            //var emailPattern = /^[a-z0-9._%+-]+@gmail\.com$/;
            var emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (!emailPattern.test(this.email_id)) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Invalid Email ID",
                    detail:this.$t("invalid_email"),
                    life: 3000,
                });
                return false;
            }
            if (this.phone.length > 10 || this.phone.length < 10) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    //detail: "Please Enter Valid Mobile Number",
                    detail:this.$t("mobile_invalid_length_error"),
                    life: 3000,
                });
                return false;
            }

            if (this.phone.length !== 10) {
                // Condition: Mobile number length is not 10
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Mobile number must be exactly 10 digits.",
                    detail:this.$t("mob_tendigit"), 
                    life: 3000,
                }); 
                return false;
            }

            if (!/^\d+$/.test(this.phone)) {
                // Condition: Mobile number contains non-numeric characters
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Mobile number must contain only digits.",
                    detail:this.$t("mobile_numeric_error"),
                    life: 3000,
                });
                return false;
            }

            if (/^0+$/.test(this.phone) || /^0/.test(this.phone)) {
                // Condition: Mobile number contains all zeros or starts with zero
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Mobile number cannot start with zero or contain all zeros.",
                    detail:this.$t("mobile_zero_error"),
                    life: 3000,
                });
                return false;
            }
















            if (this.password == '') {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Please Enter Password",
                    detail:this.$t("empty_password_error"),
                    life: 3000,
                });
                return false;
            }


            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordPattern.test(this.password)) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    //detail: "Password must be at least 8 characters long and include uppercase, lowercase, a digit, and a special character.",
                    detail:this.$t("eight_char"),
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password == '') {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Please Enter Confirm Password",
                    detail:this.$t("confirm_password_empty_error"),
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password != this.password) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                   // detail: "Confirm Password not match with Password",
                    detail:this.$t("match_pass"),
                    life: 3000,
                });
                return false;
            }


            this.$confirm.require({
                target: event.currentTarget,
                //message: 'Are you sure you want to Register?',
                message:  this.$t("areYouSureYouWantToRegister"),

                icon: 'pi pi-exclamation-triangle',
                acceptLabel: this.$t("yes"),  
                rejectLabel: this.$t("no"), 
                accept: () => {



                    var payload =
                    {
                        "name": this.name,
                        "email_id": this.email_id,
                        "password": this.password,
                        "mobile_number": this.phone,
                        "client_id": this.client_id,
                        lang:this.$i18n.locale,

                    }
                    this.isLoadingModel = true;
                    var promise = apis.reg_user(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status == true) {
                            this.$toast.add({ severity: 'success',  summary: this.$t('success'),  detail: response.data.message, life: 3000 });
                            this.reset_form();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary:this.$t('rejected'), detail: this.$t('u_reject'), life: 3000 });
                }
            });

        }

    },
};
</script>

<style scoped>
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
}

.link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.link-style:hover {
    text-decoration: underline;
    /* Add underline on hover */
}

@media screen and (min-width: 992px) {
    .lg\:px-8 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }
}
</style>
<template>
    <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #F4F6F9;">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
        <div id="card1" class="card d-flex justify-content-center">
            <div class="col-12 md:col-12">
                <h4 class="d-flex text-center" style="color: #6b719b; font-size: 20px; font-weight: 800">
                      {{$t('submit_ticket')}}
                </h4>
            </div>
            <div class="py-4 px-4 mx-0 lg:mx-8" id="contact" >
                <div class="grid justify-content-between">
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                            <h4 style="color: #6b719b; font-size: 20px; font-weight: 700">{{$t('registration')}}</h4>
                        </div>
                    </div>
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
                            <a class="link-style"  @click="onClick()"> {{$t('al_have_acc')}} ?</a>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText id="name" type="text" v-model="name" onkeypress="return event.charCode >=65 && event.charCode <= 90 || event.charCode >=97 && charCode <= 122 ||
                      event.charCode === 32" 
                             />
                            <label for="name">{{$t('name')}}</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText id="email" type="text" v-model="email_id"
                             />
                            <label for="email">{{$t('email')}}</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <!-- <InputText id="name" type="password" v-model="password"
                             /> -->
                            <!-- <label for="name">{{$t('password')}}</label> -->


                            <Password id="password1" v-model="password" :feedback="false"
                         :toggleMask="true"></Password>
                            <label for="name">{{$t('password')}}</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <!-- <InputText id="con_password" type="password" v-model="conf_password"
                             />
                            <label for="con_password"> {{$t('conf_pass')}}</label> -->
                            <Password id="con_password" v-model="conf_password" :feedback="false"
                            :toggleMask="true"></Password>
                            <label for="con_password"> {{$t('conf_pass')}}</label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="py-4 px-4 mx-0 lg:mx-8" id="contact" >
                <div class="grid justify-content-between">
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                            <h4 style="color: #6b719b; font-size: 20px; font-weight: 700">{{$t('create_ticket')}}</h4>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText id="ticket_title" type="text" v-model="title"
                             />
                            <label for="ticket_title"> {{$t('ticket_title')}}</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" >
                        <span class="p-float-label">
                        <Dropdown 
                        id="Category" 
                        v-model="ticket_category_selected" 
                        :options="ticket_category_dropdown" 
                        :value="ticket_category_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="Category">{{$t('dept')}}</label>
                        </span>
                    </div>
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown 
                        id="type" 
                        v-model="ticket_type_selected" 
                        :options="ticket_type_dropdown" 
                        :value="ticket_type_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="type">{{$t('ticket_type')}}</label>
                        </span>
                    </div> -->
                  

              <div class="field col-12 md:col-6" style="margin-top: 1%;" >
                        <span class="p-float-label">
                        <Dropdown 
                        id="ward_number" 
                        v-model="ward_no" 
                        :options="ward_number_dropdown" 
                        :value="ward_number_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="ward_number">{{$t('ward_no')}}</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <FileUpload
                            
                            class="p-button-help btn_light_blue"
                            mode="basic"
                            name="model[]"
                            
                            :auto="true"
                            :custom-upload="true"
                           
                            :choose-label="$t('Attachments')" 
                            @uploader="handleFileUpload($event)"
                        />
                    </div>
                    <div class="field col-12 md:col-3" style="margin-top: 1%;">
                        <h4 style="color: #6b719b; font-size: 20px; font-weight: 600">{{$t('Attachments')}} : {{ attachments_count }} </h4>
                    </div>
                    <div class="field col-12 md:col-12" >
                        
                        <Editor v-model="description" editorStyle="height: 200px"/>
                       
                       
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-12 d-flex text-center justify-content-center">
               <Button class="p-button-help btn_light_blue" @click="submit_ticket()" :label="$t('submit_ticket')"/>
            </div>
        </div>
        <ScrollTop />
    </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
export default {
    name: "EticketSubmitTicket",
    data() {
        return {
            isLoadingModel:false,
            fullPage:false,
            ticket_type_dropdown:[],
            ticket_type_selected:"",
            ticket_category_dropdown:[],
            ticket_category_selected:"",
            

            name:"",
            email_id:"",
            ward_no:"",
            ward_number_dropdown:[],
            password:"",
            conf_password:"",
            title:"",
            category_id:"",
            type:"",
            attachments:[],
            attachments_count:0,
            description:"",
            client_id:"",

            file_data: 
            {
                filePath: "",
                contentType: "",
            },

        };
    },
    watch:{
        async currentLocale(){
            this.getWardList(this.currentLocale);
            this.getTicketCategoryMaster(this.currentLocale);
        }
    },
    mounted() { 
        this.client_id=localStorage.getItem('client_id');
        this.client_id='65a4f82f6177e69880ece5d6';
        this.getTicketTypeMaster();
        this.getTicketCategoryMaster(this.$i18n.locale);
        this.getWardList(this.$i18n.locale);
        
    },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
    methods: {
        async getWardList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      // this.isLoadingModel = true;
      var promise = apis.getWardList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ward_number_dropdown = response.data.data;
      });
    },
        async onClick()
        {
            this.$emit("redirect_to_login");
        },
        async submit_ticket()
        {   
            
            if (this.name=='') 
            {   
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail: this.$t("enter_name"),
                    life: 3000,
                });
                return false;
            }
            if (this.email_id=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail:this.$t("email_empty_error") ,
                    life: 3000,
                });
                return false;
            }
            var emailPattern = /^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/;
           // var emailPattern =  /^[a-z0-9._%+-]+@gmail\.com$/;
            if (!emailPattern.test(this.email_id)) {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                   // detail: "Invalid Email ID",
                    detail:this.$t("invalid_email"),
                    life: 3000,
                });
                return false;
            }
            if (this.password=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail: this.$t("empty_password_error"),
                    life: 3000,
                });
                return false;
            }
            var passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordPattern.test(this.password)) {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    //detail: "Password must be at least 8 characters long and include uppercase, lowercase, a digit, and a special character.",
                    detail:this.$t("eight_char"),
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail:this.$t("confirm_password_empty_error"),
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password!=this.password) 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail:this.$t("match_pass"),
                    life: 3000,
                });
                return false;
            }
            // if(this.title=='')
            // {
            //     this.$toast.add({
            //        saverity:"error",
            //        summary: this.$t('error'),
            //        detail:this.$t("enter_title"),
            //        life:3000, 
            //     })
            //     return false;
            // }
            if (this.title=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail: this.$t("enter_title"),
                    life: 3000,
                });
                return false;
            }
            if (this.ticket_category_selected=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail: this.$t("enter_cate"),
                    life: 3000,
                });
                return false;
            }
            // if (this.ticket_type_selected=='') 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary:  this.$t('error'),
            //         detail: this.$t("ticket_type"),
            //         life: 3000,
            //     });
            //     return false;
            // }
            if (this.ward_no=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary:  this.$t('error'),
                    detail: this.$t("enter_ward"),
                    life: 3000,
                });
                return false;
            }

            

            this.$confirm.require({
                target: event.currentTarget,
                message: this.$t("are_u_sure"),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: this.$t("yes"),  // Translated "Yes"
                rejectLabel: this.$t("no"),   // Translated "No"

                accept: () => {
                    var ward_no = '';
                    if (this.ward_no) {
                        ward_no = this.ward_no.ward_no;
                    }

                    //get category id
                    this.category_id =this.ticket_category_selected._id;
                    // get ticket type
                    this.type = this.ticket_category_selected._id;

                    var payload=
                    {
                        "name"          : this.name,
                        "email_id"      : this.email_id,
                        "password"      : this.password,
                        "title"         : this.title,
                        "category_id"   : this.category_id,
                        "ward_no"       : ward_no,
                        //"type"          : this.type,
                        "attachments"   : this.attachments,
                        "description"   : this.description,
                        "client_id"     : this.client_id,
                        lang:this.$i18n.locale,
                        
                    }
                    this.isLoadingModel = true;
                    var promise = apis.submit_TicketNewUser(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            this.$toast.add({
                        severity: "success", // Change to "success", "warn", or "error" as needed
                        summary: this.$t('success'), // Change summary as needed
                        detail: response.data.message,
                        life: 3000 // Toast duration in milliseconds
                        });
                            this.reset_form();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        else
                        {
                            

                            this.$toast.add({
                                    severity: "error", // Change to "success", "warn", or "error" as needed
                                    summary: this.$t('error'), // Change summary as needed
                                    detail: response.data.message,
                                    life: 3000 // Toast duration in milliseconds
                                    });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },
        async reset_form()
        {
            this.file_data= 
            {
                filePath: "",
                contentType: "",
            }
            this.name="",
            this.email_id="";
            this.password="";
            this.conf_password="";
            this.title="";
            this.category_id="";
            this.ward_no="";
            this.type="";
            this.attachments=[];
            this.attachments_count=0;
            this.description="";
            this.ticket_category_selected="";
            this.ticket_type_selected="";
        },
        async handleFileUpload(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.attachments.push("https://eticket-docs.s3.ap-south-1.amazonaws.com/" +
                    filename);
                  this.attachments_count = this.attachments_count + 1;
                   
                });
            });

        },
        async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
        getTicketTypeMaster()
        {
            var data = {
                "client_id":"65a4f82f6177e69880ece5d6",
                user_id: localStorage.getItem("id"),
            };
            this.isLoadingModel = true;
            var promise = apis.TicketTypeMaster(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                this.ticket_type_dropdown = response.data.data;
            });
        },
        getTicketCategoryMaster()
        {
            var data = {
                "client_id":"65a4f82f6177e69880ece5d6",
                user_id: localStorage.getItem("id"),
                lang:this.$i18n.locale
            };
            this.isLoadingModel = true;
            var promise = apis.TicketCategoryMaster(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                this.ticket_category_dropdown = response.data.data;
            });
        }
    },
};
</script>
<style scoped>
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
}

.link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none; 
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px)
{
.lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
}
}
</style>